import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import avatar1 from '../../assets/images/user/avatar-1.jpg'


import { PiMegaphoneLight } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { BsPen } from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineForm, AiOutlineMinusCircle } from "react-icons/ai";

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'
// import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'

import { ProviderCreate } from './createProvider'
import { ProvidersCategories } from './ProvidersCategories'
import { CoordinatorRow } from '../components/coordinatorRow'
import EditProviderModal from './editProviderModal'


// (00) 00000-0000
const maskPhone = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

function Providers() {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    // const [userList, setUserList] = useState<any[]>([])

    const companyId = sessionStorage.getItem('company')


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)

    const [supplierList, setSupplierList] = useState<any[]>([])

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const [show, setShow] = useState(false);

    const [currentSupplierId, setCurrentcurrentSupplierId] = useState();

    const [currentSupplier, setCurrentcurrentSupplier] = useState<any>();


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)


    const inactvate = () => {
        setLoading(true)

        fetch(`${BASE.USER.url}${BASE.USER.supplier_inactivate}/${currentSupplier.id}`, {
          method: 'POST',
            headers: {
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
               window.location.reload()


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    
    const getAllSuppliers = () => {
        setLoading(true)

        fetch(`${BASE.USER.url}${BASE.USER.company_suppliers}/${companyId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setSupplierList(response.suppliers)

                // const enabledCount = response.filter(item => item.biometric).length
                // setTotalUsers({
                //     withBiometry: enabledCount,
                //     withoutBiometry: response.length - enabledCount
                // })


            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    const onSubmit = (data: any) => {
        setDisableSign(true)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                "name": "vasss planeta",
                "trade_name": "Aux-limpeza",
                "cnpj": "0000000000000000",
                "logo": "meu/logo",
                "logo_square": "versao/square",
                "main_color": "#ff00ff",
                "secondary_color": "#ff00ff",
                "status": "active",
                "isActive": true,
                "companyId": companyId,
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const createCoordinator = (data: any) => {
        setDisableSign(true)

        const temp_name = data.name.replace(/^\s+|\s+$/g, '').split(' ')


        const temp_password = `tB${Math.random().toString(36).slice(-8)}!`

        let userId;

  

        fetch(`${BASE.USER.url}${BASE.USER.user}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                firstName: temp_name[0],
                lastName: temp_name.slice(1).join(" "),
                email: data.email.replace(/^\s+|\s+$/g, '') || 'a@a.com',
                phoneNumber: data.phoneNumber
                    .replace(/"/g, '')
                    .replace(/'/g, '')
                    .replace(/\(|\)/g, ''),
                "document": data.document.replace(/^\s+|\s+$/g, ''),
                "document_type": "CPF",
                "supplierId": data.supplierId,
                "originCompanyId": companyId,
                "externalId": "provider",
                "gender": "na",
                "birthDate": "1990-01-01",
                companyName: 'Barretos',
                companyOnboarding: 'https://barretos.bepass.com.br',
                companyTemplate: 'bepass-default-barretos',
                sendMail: true
            })
        })
            .then((res) => res.json())
            .then((response) => {

        

                userId = response.sup
                fetch(`${BASE.COMPANY.url}${BASE.COMPANY.roles}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': BASE.api.api_key
                    },
                    body: JSON.stringify({
                        "userId": userId,
                        "role": "supplier",
                        "companyId": data.supplierId,
                        "originCompanyId": companyId,
                        "email": data.email,
                        "name": data.name,
                        "login": data.email.replace(/^\s+|\s+$/g, ''),
                        "password": temp_password,
                        "sendMail": true,
                        companyName: 'Barretos',
                        companyDashboard: 'https://credenciados-barretos.bepass.com.br',
                    })
                })
                    .then((res) => res.json())
                    .then((response) => {
                      window.location.reload();
                    })
                    .catch((error) => {
                        console.log(error)
                    })


            })
            .catch((error) => {
                console.log(error)
            })
    }

    const resetForm = () => {
        setDisableSign(false)
        reset()
    }


    useEffect(() => {
        getAllSuppliers()
    }, [])

    return (
        <>
            <Row>
                <Col md={6} xl={8}>

  <h2 className='table-title'>Empresas</h2>

      <div className="group-wrap">
        <ul className="group-list">
        {supplierList.length > 0 &&
          supplierList.map((user: any, index) => (
              <li key={index} className='group-item'>

                <div className="group-detail">
                  <h3 className="group-name">{user.name}</h3>
                  <ul className="group-action">
                    

                    <li>
                        {/* <span className="sidebar-icon"><AiOutlineForm/></span> */}
                        <EditProviderModal currentSupplier={{...user}} />
                    </li>
                    <li onClick={() => { setCurrentcurrentSupplierId(user.id); handleShow() }}>
                        <span className="no-icon sidebar-icon">Criar coordenador</span>
                    </li>
                    <li  onClick={() => { setCurrentcurrentSupplier(user); handleShowConfirm() }}>
                        <span className="sidebar-icon icon-red"><AiOutlineMinusCircle/></span>
                    </li>



                    {/* <Link to="#" onClick={() => { setCurrentcurrentSupplierId(user.id); handleShow() }} className="label theme-bg text-white f-12 m-l-15">
                        Criar coordenador
                    </Link>
                    <EditProviderModal currentSupplier={{...user}} />
                    <Link to="#" onClick={() => { setCurrentcurrentSupplier(user); handleShowConfirm() }} className="label theme-bg4 text-white f-12 m-l-15">
                        X
                    </Link> */}
                  </ul>
                </div>

                <div className="group-coordinators">
                  <CoordinatorRow supplierId={user.id} companyId={companyId} />
                </div>

  {/* <td>
  <h6 className="text-muted biometry-status m-t-10">
  status
  <i className={`fa fa-circle text-c-${user.isActive ? 'green' : 'red'} f-12 m-l-15`} />
  </h6>
  </td> */}
                                              
                                            </li>

                                        ))}
        </ul>
      </div>
                   
                </Col>
                <Col md={6} xl={4}>
                    <ProviderCreate />
                    {/* <ProvidersCategories /> */}
                </Col>

            </Row>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(createCoordinator)}>
                    <Form.Control
                        type="hidden"
                        defaultValue={currentSupplierId}
                        {...register('supplierId')}
                    />

                    <Modal.Header closeButton>
                        <Modal.Title>Criar coordenador</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>CPF</Form.Label>

                                <Form.Control
                                    type="text"
                                    placeholder="Documento (CPF)"
                                    {...register('document', { required: true })}
                                />
                                {errors.document && (
                                    <span className="label-error">
                                        Você precisa informar um CPF.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail</Form.Label>

                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email', { required: true })}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>

                                <Form.Control
                                    type="text"
                                    placeholder="Telefone com DDD"
                                    {...register('phoneNumber')}
                                    onChange={(event) => {
                                        const { value } = event.target
                                        event.target.value = maskPhone(value)
                                    }}
                                /></Col>

                        </Row>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Fechar
                        </Button>
                        <Button variant="primary" type='submit'>
                            Criar
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Organização</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    defaultValue={`${currentSupplier?.name}`}
                                    placeholder="Nome"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>CNPJ</Form.Label>
                                <Form.Control
                                    defaultValue={`${currentSupplier?.cnpj}`}
                                    type="text"
                                    placeholder="CNPJ"
                                    {...register('cnpj')}
                                />
                                {errors.cnpj && (
                                    <span className="label-error">
                                        Você precisa informar um CNPJ
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Razão Social</Form.Label>
                                <Form.Control
                                    defaultValue={`${currentSupplier?.trade_name}`}
                                    type="text"
                                    placeholder="Razão Social"
                                    {...register('trade_name')}
                                />
                                {errors.trade_name && (
                                    <span className="label-error">
                                        Você precisa informar uma Razão Social
                                    </span>
                                )}</Col>

                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleCloseOrg}>
                        Editar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza que deseja excluir {currentSupplier ? currentSupplier.name:''}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={inactvate}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Providers
