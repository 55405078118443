import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import avatar1 from '../../assets/images/user/avatar-1.jpg'

import alma from '../../assets/images/logo-alma.png'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'
import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'
import { OrganizationList } from './listOrganization'

function Organization() {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    // const [userList, setUserList] = useState<any[]>([])


    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [disableSign, setDisableSign] = useState(false)

    const [userList, setUserList] = useState<any[]>([
        {
            "name": "barretos",
            "access_code": null,
            "token": "$2a$10$r8Ah7cr4m1RAgwVAq8oT7.n5gOjqGxQlehvfITN9ZPWbKUkHsJdff",
            "externalId": "190",
            "hikId": "da85ecda9d0011eda8fc0242ac120002",
            "active": true,
            "userAvatar": '',
            coordinator: "Flavio Silveira",
            "syncStatus": [

            ]
        },
        {
            "name": "Tom Brasil",
            "access_code": null,
            "token": "$2a$10$szP4h29fFYRZEk3iZZ7iheEE3a4yynOq22iWQT3VP.UtIEvBICafO",
            "externalId": "777",
            "hikId": "963b3fbdf0df4dcd90e7ad699e08c282",
            "active": false,
            "userAvatar": 'tombrasil',
            coordinator: "Romulo Pires",
            "syncStatus": null
        },
        {
            "name": "Alma Viva",
            "access_code": null,
            "token": "$2a$10$pYZxO/Zswo6cISB3UIy3lu0jcseLl51aoD82p1vVt3FL5Q/vmglB6",
            "externalId": "888",
            "hikId": "00cbdb77a8fb402d97afe7628c8cad75",
            "active": false,
            "userAvatar": alma,
            coordinator: "John Carter",
            "syncStatus": null
        }
    ])

    const [isLoading, setLoading] = useState(false)

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0
    })

    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    // const getAllUsers = () => {
    //     setLoading(true)
    //     fetch(`${BASE.api.base_url}${BASE.api.find_allusers_with_biometry}`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: CONFIG.API_KEY
    //         },
    //         body: JSON.stringify({
    //             originCompany: 'da85ecda-9d00-11ed-a8fc-0242ac120002'
    //         })
    //     })
    //         .then((resg) => resg.json())
    //         .then((response) => {
    //             setLoading(false)
    //             setUserList(response)

    //             const enabledCount = response.filter(item => item.biometric).length
    //             setTotalUsers({
    //                 withBiometry: enabledCount,
    //                 withoutBiometry: response.length - enabledCount
    //             })


    //         })
    //         .catch((error) => {
    //             setLoading(false)
    //             console.log(error)
    //         })
    // }


    const onSubmit = (data: any) => {
        setDisableSign(true)

        // const username = string_to_slug(data.name)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                password: `AM${Math.random().toString(36).slice(-8)}!`,
                role: data.type || 'default'
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }


    const resetForm = () => {
        setDisableSign(false)
        reset()
    }


    useEffect(() => {
        // getAllUsers()
    }, [])

    return (
        <>
            <Row>
                <Col md={6} xl={8}>
                    <OrganizationList />
                </Col>
                <Col md={6} xl={4}>
                    <OrganizationCreate />
                </Col>

            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar coordenador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Organização</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleCloseOrg}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={handleCloseConfirm}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Organization
