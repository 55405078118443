import React, { Component, useCallback, useEffect, useState } from 'react'
import { BASE } from '../../basedata/constants'

import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'

import Modal from 'react-bootstrap/Modal';

import { useForm } from 'react-hook-form';

import { CONFIG } from '../../config/configVariables'

import './event.sass'

import { Link, useParams } from 'react-router-dom'

import { initFlowbite } from 'flowbite'

// import * as XLSX from 'xlsx';
import { utils, read, writeFile } from 'xlsx';
import Dropzone from 'react-dropzone';

import { PiMegaphoneLight } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";


import faceOK from '../../images/icon-smile.svg'
import faceNOK from '../../images/icon-smile-dotted.svg'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { MdOutlineFileDownload, MdOutlineAssignmentInd } from 'react-icons/md';


const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const MySwal = withReactContent(Swal)

export default function ShowEventProvider() {
    
  sessionStorage.setItem('updatx001', 'true');
  const auth = sessionStorage.getItem('updatx001')
  if (auth !== 'true') {
        sessionStorage.clear();
        window.location.href = '/'
  }


  const dateFormatter = (dateTime:string)=>{

    const options:any = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const oldDate = new Date(dateTime)
    return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
  }

    const { eventId, supplierId } = useParams()

    const [loading, setLoading] = useState(false)

    // User list uploader
    const [tempUsersToLoad, setTempUsersToLoad] = useState<any>(null);
    const [tempUserCheck, setTempUserCheck] = useState<any>(null);
    const [usersListColumns, setUsersListColumns] = useState<any>([]);


    //User validation response
    const [responseCounter, setResponseCounter] = useState({
        error: 0,
        success: 0,
        return: false
    });
    const [errorList, setErrorList] = useState<any>({
        simpleList: [],
        fullList: []
    });

    //User list
    const [userCommonList, setUserCommonList] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    const [usersTotal, setUsersTotal] = useState(0);

    //List handlers
    const [userIdHandlerList, setUserIdHandlerList] = useState<any>([]);
    const [searchBar, setSearchBar] = useState('null')
    const [searchTimeout, setSearchTimeout] = useState<any>(null)
    const [searchData, setSearchData] = useState<any>({});

    const getEventUsers = (searchTerm: any = null) => {
        // /eventUsers/:eventId/:supplierId

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                page,
                pageSize,
                search: searchTerm
            })
        };
        fetch(`${BASE.EVENT.url}${BASE.EVENT.users_provider}/${eventId}/${supplierId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setUserCommonList(data.result)
                setTotalPages(Math.ceil(data.totalItems / pageSize));
                setUsersTotal(data.totalItems || 0)
            });
    }

    const handleFileUpload = (acceptedFiles: any) => {


        setTempUsersToLoad(null);
        setTempUserCheck(null)

        setResponseCounter({
            error: 0,
            success: 0,
            return: false
        })
        setErrorList({
            simpleList: [],
            fullList: []
        })


        const fileReader = new FileReader();
        fileReader.onload = (e: any) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: 'array' });

            // Assuming the first sheet is the one you want to parse
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert sheet to JSON
            const jsonData = utils.sheet_to_json(sheet);

            // Log rows by columns
            // jsonData.forEach((row) => {
            //   Object.keys(row).forEach((column) => {
            //     console.log(`Column: ${column}, Value: ${row[column]}`);
            //   });
            // });


            setShowDropDown(false)
            document.getElementById('orla')?.click()
            setTempUsersToLoad(jsonData);
            setTempUserCheck(jsonData[0])
        };

        fileReader.readAsArrayBuffer(acceptedFiles[0]);
    };

    const verifyUsersJson = () => {
        setLoading(true)
        const newUserField: any = []
        // Object.keys(tempUserCheck).forEach((column, index) => {

        for (const itemList of tempUsersToLoad) {
            let requestObject = {};
            for (let currentColumn of usersListColumns) {
                currentColumn = currentColumn.split('|')
                const currentItemIndex = Object.keys(itemList).indexOf(currentColumn[1])
                if (currentItemIndex >= 0) {
                    requestObject = {
                        ...requestObject,
                        [currentColumn[0]]: itemList[currentColumn[1]]
                    }
                }
                // continue
            }
            const uidx = (new Date().getTime()).toString(36)
            requestObject = {
              ...requestObject,
              "supplierId": supplierId,
              originCompanyId: BASE.COMPANY_DATA.company_id,
              externalId: `bp-${uidx}`,
              gender: 'na',
              document_type: 'na',
              birthDate: "1990-01-01"
            }
            newUserField.push(requestObject)
        }

        console.log('| | | | | ')
        console.log(newUserField)
        console.log('|+ |+ |+ |+ |+ ')


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                eventId: eventId,
                supplierId: supplierId,
                userList: newUserField
            })
        };
        fetch(`${BASE.USER.url}${BASE.USER.create_bulk}/${eventId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                // setTempUserCheck(null)
                setLoading(false)
                setResponseCounter({
                    error: data.countError || 0,
                    success: data.countSuccess || 0,
                    return: true
                })
                setErrorList({
                    simpleList: data.errorData.slice(0, 4),
                    fullList: data.errorData
                })
                getEventUsers()
            });
    }

    const deleteUserFromList = (selectedIndex: any) => {
        setTempUserCheck(null)
        const _tempUserCheck = tempUserCheck;
        delete _tempUserCheck[selectedIndex]


        setTimeout(() => {
            if (Object.keys(_tempUserCheck).length < 1) {
                console.log('oiee')
                return setTempUserCheck(null)
            }
            console.log(selectedIndex)
            console.log(tempUserCheck)
            setTempUserCheck(_tempUserCheck)
        }, 100);
    }

    const selectColumnName = (item: any, column: any, key: any) => {
        const _tempUserListColumns: any = usersListColumns
        const regex = new RegExp(`${item.target.value}|${column}|${key}`);

        if (_tempUserListColumns.length > 0) {
            if (regex.test(_tempUserListColumns.join())) {
                _tempUserListColumns[key] = `${item.target.value}|${column}|${key}`
            } else {
                _tempUserListColumns.splice(key, 0, `${item.target.value}|${column}|${key}`)
            }
            setUsersListColumns(_tempUserListColumns)
        } else {
            _tempUserListColumns.splice(key, 0, `${item.target.value}|${column}|${key}`)
        }
    }

    const downloadErrorSheet = () => {
        const worksheet = utils.json_to_sheet(errorList.fullList);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        writeFile(workbook, `error_list.xlsx`);
    }

    const removeUsersFromEvent = (userIds: any) => {


        fetch(`${BASE.EVENT.url}${BASE.EVENT.remove_user}/${eventId}/${supplierId}`,
        {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': BASE.api.api_key
                },
                body: JSON.stringify({
                  ids: userIds
                })
            }
        )
        .then((res) => res.json())
        .then((response) => {
          
                const usersId = response.map((item) => item.id)
                console.log(usersId)
            
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "eventId": eventId,
                        "userId": usersId
                    })
                };
                fetch(`${BASE.EVENT.url}${BASE.EVENT.remove_user}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        window.location.reload()
                    });
            })
            .catch((error) => {
                console.log(error)
                window.location.reload()
            })

    }

    const removeBiometries = (usersId: any) => {

      if(!confirm('Tem certeza?')){
        return false
      }

  
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              tokens: usersId
          })
      };
      fetch(`${BASE.BIOMETRY.url}${BASE.BIOMETRY.remove_token}`, requestOptions)
          .then(response => response.json())

      Swal.fire({
        position: "top",
        icon: "success",
        title: "Biometrias deletadas!",
        showConfirmButton: false,
        timer: 1500
      });
    getEventUsers()

    }

    useEffect(() => {
        getEventUsers()
        initFlowbite()
    }, [page])

    useEffect(() => {
        initFlowbite()
    }, [userCommonList])

    const checkHandler = (userId: any) => {
        let newUserIdList: any = userIdHandlerList
        if (newUserIdList.indexOf(userId) >= 0) {
            newUserIdList = newUserIdList.filter((item: any) => item !== userId)
        } else {
            newUserIdList.push(userId)
        }
        setUserIdHandlerList(newUserIdList)
        getEventUsers()
    };

    function handlePrev() {
        setPage(page - 1);
    }

    function handleNext() {
        setPage(page + 1);
    }

    function getPageNumbers() {

        const numbers: any = [page];


        if (totalPages <= 6) {
            for (let i = 2; i <= totalPages; i++) {
                if (i === page) {
                    continue
                }
                numbers.push(i);
            }
        } else {

            for (let i = 2; i <= totalPages - 1; i++) {

                if (i === page - 1) {
                    numbers.push(Number(i));
                } else if (i === page + 1) {
                    numbers.push(i);
                }
            }
        }


        return numbers.sort(function (a: any, b: any) {
            return a - b;
        });
    }

    const searchBox = (event: any) => {
        const value = event.target.value;
        setSearchBar(value);
        setSearchData(null)

        // Clear the previous timeout
        clearTimeout(searchTimeout);

        // Set a new timeout
        const timeout = setTimeout(() => {
            console.log(`La bosqueta: ${value}`)
            getEventUsers(value)
        }, 1000);

        setSearchTimeout(timeout);
    }

    const sendMessage = (idsToSend: any, messageType: any) => {


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                type: messageType,
                userList: idsToSend
            })
        };
        fetch(`${BASE.JAIMINHO.url}${BASE.JAIMINHO.send}`, requestOptions)
            .then(data => {
                Swal.fire({
                    position: "top",
                    icon: "success",
                    title: "Mensagem Enviada",
                    showConfirmButton: false,
                    timer: 1500
                  });
            });

    }

    const [show, setShow] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false)

    const handleClose = () => setShow(false);
    const handleCloseDropDown = () => setShowDropDown(false);
    const handleShow = () => setShow(true);
    const handleShowDropDown = () => setShowDropDown(true);

    const {
      register,
      handleSubmit,
      setError,
      reset,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {

      const dateFormate = data.birthday.split('/')
      const validateDate = new Date(
        dateFormate[1] + '/' + dateFormate[0] + '/' + dateFormate[2]
      )
      const today = new Date()

      if (
        validateDate.getTime() > today.setHours(0, 0, 0, 0) ||
        validateDate.toString() === 'Invalid Date' ||
        data.birthday.length < 10
      ) {
        setError('birthday', {
          type: 'manual',
          message: 'Você precisa informar uma data válida'
        })
        return false
      }

      if (!isValidEmail(data.email)) {
        setError('email', {
          type: 'manual',
          message: 'Você precisa informar um E-mail válido'
        })
        return false
      }

      if (data.celphone.length < 12) {
        setError('celphone', {
          type: 'manual',
          message: 'Você precisa informar um telefone válido'
        })
        return false
      }

      const userData = [{
        name: data.name,
        externalId: data.document.replace(/^\s+|\s+$/g, ''),
        gender: '',
        document: data.document.replace(/^\s+|\s+$/g, ''),
        document_type: '',
        email: data.email.replace(/^\s+|\s+$/g, ''),
        phoneNumber: data.celphone
          .replace(/"/g, '')
          .replace(/'/g, '')
          .replace(/\(|\)/g, ''),
        birthDate:
          dateFormate[2] + '/' + dateFormate[1] + '/' + dateFormate[0],
        nationality: '',
        accessCode: data.document,
        originCompanyId: 'd5c19737-7e4e-4dd8-9931-d6bc3bc961d1',
        supplierId: supplierId,
        sector: data.sector,
        licensePlate: data.licensePlate
      }]

      
    fetch(`${BASE.USER.url}${BASE.USER.create_bulk}/${eventId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': BASE.api.api_key
      },
      body: JSON.stringify({
        eventId: eventId,
        supplierId: supplierId,
        userList: userData
      })
    })
      .then((res) => res.json())
      .then((data) => {
        
        setLoading(false)
            setResponseCounter({
                error: data.countError || 0,
                success: data.countSuccess || 0,
                return: true
            })
            setErrorList({
                simpleList: data.errorData.slice(0,4),
                fullList: data.errorData
            })
        handleClose()
        getEventUsers()
        setShowDropDown(false)
      }
    )
      .catch((error) => {
        console.log(error)
      })
    }


    const [onboardingLinkText, setOnboardinLinkText] = useState({
        buttonText: 'Link Onboarding', itemToken: ''
    })
    const getOnboardingLink = (token: any) => {

        navigator.clipboard.writeText(`https://barretos.bepass.com.br/?token=${token}&cred=true`)

        setOnboardinLinkText({buttonText: 'Link Copiado!', itemToken: token})
        setTimeout(() => {
            setOnboardinLinkText({buttonText: 'Link Onboarding', itemToken: ''})
        }, 2000);
        return
    }


    const [eventDetail, setEventDetail] = useState<any>(null);
    const [eventDataTransformed, setEventDataTransformed] = useState<any>({})

    const dateConvert = (date: any) => {
        const startDateTime = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        setEventDataTransformed({
            dataTransformDay: startDateTime.getDate().toString().padStart(2, '0'),
            dataTransformMonth: new Intl.DateTimeFormat("pt-BR", { month: "short" }).format(startDateTime),
            dataTransformTime: `${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`,
        })
        return `${startDateTime.toLocaleDateString(
            'pt-br'
        )} ${startDateTime.getHours()}:${startDateTime.getMinutes().toString().padStart(2, '0')}`
    }

    const getEventDetail = () => {
        const requestOptions = {
            method: 'Get',
        };
        fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${eventId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                dateConvert(data.event.startDateTime)
                setEventDetail(data.event)
            });
    }

    useEffect(() => {
        getEventDetail()
    }, [])


    const getUserImage = (token: any) => {
      fetch(`${BASE.BIOMETRY.url}${BASE.BIOMETRY.get_image_biometry}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              token: token
          })
      })
          .then((resg) => resg.json())
          .then((response) => {

              const userImage = response.image ? `data:image/png;base64,${response.image}`:`https://api.qrserver.com/v1/create-qr-code/?size=300x300&bgcolor=ffffff&data=https://barretos.bepass.com.br/?token=${token}&cred=true`

              setLoading(false)
              // generateLog({ affectationId: token }, 'download user Image')
              // setCurrentUserImage(response.image)

              MySwal.fire({
                  html:  <img
                  src={userImage}
                  className="qr-code"
                    alt="Pessoa"
                  />,
                  confirmButtonColor: '#ccc',
                })
          })
          .catch((error) => {
              setLoading(false)
              console.log(error)
          })
  }

  const downloadTickets = (supplierId) => {

    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_report}/${supplierId}`, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
    },
    })
    .then((resg) => resg.json())
    .then((response) => {

      const downloadList = response.map((item) => {
  
        return {
          nome: `${item.firstName} ${item.lastName}`,
          empresa: item.groupName || 'na',
          documento: item.document || 'na',
          entrada: item.entrance !== null ? dateFormatter(item.entranceDate):'--',
          funcao: item.function || 'na',
          setor: item.sector || 'na',
          onboarding: `https://barretos.bepass.com.br/?token=${item.token}`,
          telefone: item.phoneNumber ||  'na'
        }
      })

      const worksheet = utils.json_to_sheet(downloadList);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'relatorio');
      writeFile(workbook, `relatorio.xlsx`);
    })
    .catch((error) => {
      console.log(error)
    })



  }
  
    return (
        <>
            <div className='all-page sm:ml-64'>
                <div className='all-page-content'>

                    <div className='grid lg:grid-cols-8 md:grid-cols-3 gap-4 mb-4'>
                        <div className="col-span-3">
                            {/* <EventHeaderSnippet /> */}
                        </div>

                        {/* enviar arquivo */}
                        <div id="default-modal" className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
                            <div className="relative w-full max-w-md max-h-full">
                                <div className="relative bg-white rounded-lg shadow">
                                    <button id="orla" type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                                        <svg className="w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                    <div className="px-6 py-6 lg:px-8">
                                        <h3 className="mb-4 text-xl font-medium text-gray-900 ">Envie um arquivo</h3>

                                        <div className='card'>
                                            <Dropzone onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()} className="modal-drag-drop">
                                                        <input {...getInputProps()} />
                                                        <p>Arraste e solte o arquivo excel (.xlsx) aqui, ou click para selecionar um</p>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <a href="/samples/planilha_exemplo.xlsx" className="sample-link">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"></path>
                                                </svg>
                                            </span>baixar exemplo
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Modal className='dropfile-modal' show={showDropDown} onHide={handleClose}>
                            <Modal.Header>
                                <h3>Envie um arquivo</h3>
                                <i><LiaTimesSolid /></i>
                            </Modal.Header>
                            <Modal.Body>

                                <div className="dropfile-box">
                                    <Dropzone onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="modal-drag-drop">
                                                <input {...getInputProps()} />
                                                <p>Arraste e solte o arquivo excel (.xlsx) aqui, ou click para selecionar um</p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>

                                <a href="/samples/planilha_exemplo.xlsx" className="sample-link">
                                    <i><LiaFileDownloadSolid /></i>
                                    baixar exemplo
                                </a>
                            </Modal.Body>
                        </Modal>

                        <Modal className='dropfile-modal' show={show} onHide={handleClose}>
        <Modal.Header className='modal-header'>
          <h3>Cadastrar um convocado</h3>
          <i onClick={handleClose} className='modal-header'><LiaTimesSolid/></i>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-wrapper custom-form-input">
            <div className="form-floating mb-3">
              <input
                className="form-control form-control-lg"
                placeholder="Nome"
                {...(register('name'))}
              />
              <label className="form-label" htmlFor="name">
                Nome completo
              </label>
              {errors.name && (
                <span className="label-error">
                  Você precisa informar um Nome válido
                </span>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                className="form-control form-control-lg"
                placeholder="Data nascimento"
                {...register('birthday')}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskDate(value)
                }}
              />
              <label className="form-label" htmlFor="birthday">
                Data de nascimento
              </label>
              {errors.birthday && (
                <span className="label-error">
                  Você precisa informar uma data válida
                </span>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                className="form-control form-control-lg"
                placeholder="CPF"
                {...register('document')}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskCPF(value)
                }}
              />
              <label className="form-label" htmlFor="document">
                CPF
              </label>
              {errors.document && (
                <span className="label-error">
                  Você precisa informar um Documento válido
                </span>
              )}
            </div>

            <div className="form-floating mb-3">
              <select 
                className="form-control form-control-lg"
                {...register('gender')}
                >
                  <option disabled selected value="">Selecione</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                  <option value="Preferir não declarar">Preferir não declarar</option>
              </select>
              <label className="form-label" htmlFor="gender">
                Gênero
              </label>
              {errors.gender && (
                <span className="label-error">
                  Você precisa informar um Gênero válido
                </span>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                className="form-control form-control-lg"
                placeholder="E-mail"
                {...register('email')}
              />
              <label className="form-label" htmlFor="email">
                E-mail
              </label>
              {errors.email && (
                <span className="label-error">
                  Você precisa informar um E-mail válido
                </span>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                className="form-control form-control-lg"
                placeholder="Celular"
                {...register('celphone', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskPhone(value)
                }}
              />
              <label className="form-label" htmlFor="celphone">
                Celular
              </label>
              {errors.celphone && (
                <span className="label-error">
                  Você precisa informar um telefone válido
                </span>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                className="form-control form-control-lg"
                placeholder="Setor"
                {...register('sector')}
                onChange={(event) => {
                  const { value } = event.target
                }}
              />
              <label className="form-label" htmlFor="sector">
                Setor
              </label>
              {errors.sector && (
                <span className="label-error">
                  Você precisa informar um Setor válido
                </span>
              )}
            </div>

            <div className="form-floating mb-3">
              <input
                className="form-control form-control-lg"
                placeholder="Placa do veículo"
                {...register('licensePlate')}
              />
              <label className="form-label" htmlFor="licensePlate">
                Placa do veículo
              </label>
            </div>
          </div>

          <div className="form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Enviar"
              onClick={() => clearErrors()}
            />
          </div>
        </form>

        </Modal.Body>
      </Modal>

                        <div className="event-action-top">

                            {eventDetail && (
                                <div className="event-action-top-info">
                                    <div className="square-button rounded-sm">
                                        <span className="event-info-month">
                                            {eventDataTransformed.dataTransformMonth}
                                        </span>
                                        <span className="event-info-date">
                                            {eventDataTransformed.dataTransformDay}
                                        </span>
                                    </div>
                                    <div className="square-button event-title rounded-sm">
                                        <span className="event-info-time">
                                            {eventDataTransformed.dataTransformTime}
                                        </span>
                                        <span className="event-info-title">
                                            {eventDetail.title}
                                        </span>
                                    </div>
                                </div>
                            )}

                            <div className='event-action-top-buttons'>

                            <div onClick={() => downloadTickets(supplierId)} className="square-button call-buttom rounded-sm">
                                    <i className='custom-icon'>
                                        <MdOutlineFileDownload />
                                    </i>
                                    <span>Relatório</span>
                                </div>

                                <div onClick={handleShow} className="square-button call-buttom rounded-sm">
                                    <i className='custom-icon'>
                                      <MdOutlineAssignmentInd/>
                                    </i>
                                    <span>Conv. Individual</span>
                                </div>
                           
                                <div onClick={handleShowDropDown} className="square-button call-buttom rounded-sm">
                                    <i className='custom-icon'>
                                        <PiMegaphoneLight />
                                    </i>
                                    <span>Convocar</span>
                                </div>


                                <div className="square-button all-event-buttom rounded-sm">
                                    <span className="big-counter">{usersTotal}</span>
                                    <span>Prestadores</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    {(tempUserCheck && !responseCounter.return) && (

                        <div className="upload-check-table">
                            <h2 className='table-title'>Verifique os campos e dados enviados</h2>
                            <div className="relative overflow-x-auto">
                                <table className="w-full text-sm text-left text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Coluna no Arquivo
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Pré-visualização
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Campo
                                            </th>
                                            <th scope="col" className="px-6 py-3">

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.keys(tempUserCheck).map((column, key) => (

                                            <tr key={key} className="bg-white border-b  ">
                                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                                                    {column}
                                                </th>
                                                <td>
                                                    {tempUserCheck[column]}
                                                </td>
                                                <td>
                                                    <select
                                                        onChange={(item) => selectColumnName(item, column, key)}
                                                    >
                                                        <option>Selecione</option>
                                                        <option value="name">Nome</option>
                                                        <option value="document">CPF</option>
                                                        <option value="email">E-mail</option>
                                                        <option value="phoneNumber">Celular</option>
                                                        <option value="sector">Setor</option>
                                                        <option value="function">Função</option>
                                                        <option value="externalId">Código Interno</option>
                                                        <option value="company">Empresa</option>
                                                        <option value="licensePlate">Placa</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <button onClick={() => deleteUserFromList(column)} className="btn-muted btn-exclude">excluir</button>
                                                </td>
                                            </tr>

                                        )
                                        )
                                        }


                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td colSpan={4} className='align-right'>
                                                {loading ? (
                                                    <div className="bar-loader active"></div>
                                                ) : (
                                                    <button onClick={() => verifyUsersJson()} type="button" className="button-default rounded-md">
                                                        Salvar
                                                        <svg className='button-icon' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 16">
                                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2.707 14.293 5.586-5.586a1 1 0 0 0 0-1.414L2.707 1.707A1 1 0 0 0 1 2.414v11.172a1 1 0 0 0 1.707.707Z" />
                                                        </svg>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                        </div>
                    )
                    }


                    {responseCounter.return &&
                        <>
                            <h2 className='table-title'>Dados enviados com sucesso!</h2>
                            <Row>

                                <Col sm={6}>

                                    <div className="sample-error-list">
                                        <table>
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                                <tr>
                                                    <th scope="col">
                                                        Cadastros com erro
                                                    </th>
                                                    <th scope="col">
                                                        Erro
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {errorList.simpleList &&
                                                    errorList.simpleList.map((item: any, index) => (
                                                        <tr key={index} className="bg-white">
                                                            <th scope="row">
                                                                <div>
                                                                    <div className="text-base font-semibold">{item.name || '--'}</div>
                                                                    <div className="font-normal text-gray-500">{item.document || '--'}</div>
                                                                </div>
                                                            </th>
                                                            <td>
                                                                {item.message}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>

                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4} className='align-right'>
                                                        <button onClick={() => downloadErrorSheet()} type="button" className="button-default btn-info rounded-md">
                                                            Baixar Todos
                                                            <svg className="button-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 10">
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1.707 2.707 5.586 5.586a1 1 0 0 0 1.414 0l5.586-5.586A1 1 0 0 0 13.586 1H2.414a1 1 0 0 0-.707 1.707Z"></path>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>



                                </Col>
                                <Col sm={3}>

                                    <div className="show-numbers error-numbers rounded-lg">
                                        <div className="big-numbers">
                                            {responseCounter.error || 0}
                                        </div>
                                        <p className="numbers-description">
                                            Pessoas com erro no cadastro
                                        </p>
                                    </div>


                                </Col>
                                <Col sm={3}>

                                    <div className="show-numbers rounded-lg">
                                        <div className="big-numbers">
                                            {responseCounter.success || 0}
                                        </div>
                                        <p className="numbers-description">
                                            Pessoas cadastradas com sucesso!
                                        </p>
                                    </div>

                                </Col>
                            </Row>




                        </>

                    }

                    <div className="people-table">
                        <h2 className='table-title'>Pessoas convocadas</h2>

                        <div className="table-action-bar">

                            <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className="button-dropdown" type="button">
                                Ações
                                <GoChevronDown />
                            </button>


                            <div id="dropdown" className="hidden dropdown-box">

                                <ul className="dropdown-list" aria-labelledby="dropdownDefaultButton">

                                    <li>
                                        <a onClick={() => sendMessage(userIdHandlerList, 'email')} className="block px-4 py-2 hover:bg-gray-100">Enviar E-mail</a>
                                    </li>

                                </ul>

                                <ul className="dropdown-list dropdown-list-hr" aria-labelledby="dropdownDefaultButton">
                                    <li>
                                        <a onClick={() => removeBiometries(userIdHandlerList)} className="block px-4 py-2 hover:bg-gray-100">Remover Biometria</a>
                                    </li>
                                    <li>
                                        <a onClick={() => removeUsersFromEvent(userIdHandlerList)} className="block px-4 py-2 hover:bg-gray-100">Excluir</a>
                                    </li>
                                </ul>
                            </div>



                            <div className="table-action-search">
                                <label htmlFor="table-search" className="sr-only">Search</label>
                                <i>
                                    <PiMagnifyingGlassLight />
                                </i>
                                <input onChange={searchBox} type="text" id="table-search-users" placeholder="Buscar usuários (Nome ou CPF)" />
                            </div>
                        </div>

                        <Table responsive hover>
                            <thead className="table-people-header">
                                <tr>
                                    <th scope="col">
                                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500    focus:ring-2  " />
                                        <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                    </th>
                                    <th scope="col">
                                        Dados
                                    </th>
                                    <th scope="col">
                                        Biometria
                                    </th>
                                    <th scope="col">
                                        Função
                                    </th>
                                    <th scope="col">
                                        Setor
                                    </th>
                                    <th>
                                        Placa
                                    </th>
                                    <th colSpan={2} scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='table-people-data'>
                                {userCommonList?.length > 0 ? (
                                    userCommonList.map((item: any) => (
                                        <tr key={item.document}>
                                            <td>
                                                <div className="flex items-center">
                                                    <input checked={userIdHandlerList.indexOf(item.hikId) >= 0 ? true : false} onClick={() => checkHandler(item.hikId)} id={item.hikId} type="checkbox" />
                                                    <label htmlFor={item.hikId} className="sr-only">checkbox</label>
                                                </div>
                                            </td>
                                            <td scope="row" className="table-people-info">
                                                <img onClick={() => getUserImage(item.token)}  src={item.biometric ? faceOK:faceNOK} alt={item.firstName} />
                                                <div>
                                                    <h3>{item.firstName} {item.name}</h3>
                                                    <h4>{item.document}</h4>
                                                </div>
                                            </td>

                                            <td onClick={() => removeBiometries([item.token])}>
                                                <i className={`status-circle ${item.biometric ? 'ok':'nok'}`}>
                                                  </i> {item.biometric ? 'OK (excluir?)':'FALTA'}
                                            </td>

                                            <td>
                                                {item.function || 'N/A'}
                                            </td>
                                            <td>
                                                {item.sector || 'N/A'}
                                            </td>
                                            <td>
                                                {item.licensePlate || 'N/A'}
                                            </td>
                                            <td className='table-people-single-action'>
                                                <p className='share-text'>enviar onboaridng:</p>

                                                <button onClick={() => sendMessage([item.hikId], 'email')} type="button" className="button-default button-share rounded-md">
                                                    e-mail
                                                </button>

                                                <button onClick={() => getOnboardingLink(item.token)} type="button" className="button-default button-share rounded-md">

                                                    {item.token === onboardingLinkText.itemToken ? onboardingLinkText.buttonText : 'Link Onboarding'}
                                                </button>
                                            </td>
                                            <td>
                                                <button onClick={() => removeUsersFromEvent([item.hikId])} className='fake-link soft-exclude'>
                                                    excluir
                                                </button>
                                            </td>
                                        </tr>
                                    ))) : (
                                    <tr>
                                        <td colSpan={8}>
                                            <div className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 text-center" role="alert">
                                                <span className="font-medium">:(</span> Nenhuma pessoa encontrada para o evento.
                                            </div>
                                        </td>
                                    </tr>
                                )
                                }
                            </tbody>
                            {userCommonList?.length > 0 &&
                                <tfoot className='hidden'>
                                    <tr>
                                        <td colSpan={6}>
                                            <nav aria-label="navigation">
                                                <ul className="flex pagination items-center justify-center h-10 text-base">
                                                    <li key={'prev'}>
                                                        <button disabled={page === 1} onClick={handlePrev} className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700">
                                                            <span className="sr-only">Previous</span>
                                                            <svg className="w-3 h-3 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                                                            </svg>
                                                        </button>
                                                    </li>

                                                    {
                                                        (page > 1) && (
                                                            <li key="uno" className={`${page === 1 ? 'active' : ''}`} onClick={() => { setPage(1); getPageNumbers() }}>
                                                                <a href="#" className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}>1</a>
                                                            </li>
                                                        )}
                                                    {(page > 4) && (
                                                        <li>
                                                            <p className="text-lg font-medium text-gray-900 dark:text-white">...</p>
                                                        </li>
                                                    )
                                                    }

                                                    {getPageNumbers().map((pageItem: any) =>
                                                        <li className={`${page === pageItem ? 'active' : ''}`} key={pageItem} onClick={() => { setPage(pageItem); getPageNumbers() }}>
                                                            <a href="#" className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}>{pageItem}</a>
                                                        </li>
                                                    )}



                                                    {(page < (totalPages - 3)) && (
                                                        <li>
                                                            <p className="text-lg font-medium text-gray-900 dark:text-white">...</p>
                                                        </li>
                                                    )
                                                    }
                                                    {
                                                        (page < (totalPages - 3)) && (
                                                            <li key="zero" className={`${page === totalPages ? 'active' : ''}`} onClick={() => { setPage(totalPages); getPageNumbers() }}>
                                                                <a href="#" className={` flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}>{totalPages}</a>
                                                            </li>
                                                        )
                                                    }


                                                    <li key={'next'} >
                                                        <button disabled={page === totalPages} onClick={handleNext} className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700">
                                                            <span className="sr-only">Next</span>
                                                            <svg className="w-3 h-3 rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                                            </svg>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </td>
                                    </tr>
                                </tfoot>
                            }
                        </Table>
                    </div>

                </div>
            </div>

        </>
    )
}
